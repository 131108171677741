// Libraries
import React, { useContext, useState, useRef } from 'react';
import { View, Text, StyleSheet, ImageBackground, Dimensions, TouchableOpacity } from 'react-native';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Functions
import { openUrlNewTab } from '../functions/common';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { RESET_PARAM, SET_PARAM, SET_PARAM_CACHE } from '../context/actions/commonActions';

// Components
import { P, H1, H2, H3, H4, Link } from '../components/text';
import { PictoBox } from '../components/images';
import { Button } from './button';

// Assets

// Default component
export default function Footer( props ) {
  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo_small.png');
  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;

  //Props
  const navigation = props.navigation;
  const logo = props.logo || { source: Logo }
  const title = props.title || 'Module titel';
  const align = props.align || 'center';
  const mobile = props.mobile || false;
  const subtitle = props.subtitle || 'Module subtitel';
  const disclaimer = props.disclaimer || function () { console.log('disclaimer') };
  const cookies = props.cookies || function () { console.log('cookies') };
  const toegankelijkheid = props.toegankelijkheid || function () { console.log('toegankelijkheid') };
  //const onClick = props.onClick || function () { console.log('Terug naar home') };

  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const isHovered = useHover(ref);
  const isHovered1 = useHover(ref1);
  const isHovered2 = useHover(ref2);
  const isHovered3 = useHover(ref3);

  const homeFooter = () => {
    commonDispatch({ type: RESET_PARAM });
    props.navigation.push('Main');
  }

  return (
    <View>
      { mobile ? 
        <View>
          <View style={{ marginLeft: 15, marginRight: 15, marginTop: 10, marginBottom: 10, flexDirection: 'row', flex: 100}}>
                
            { global.settings.show_disclaimer === '1' ?
              <TouchableOpacity 
              accessibilityViewIsModal={true}
              accessibilityLabel='Disclaimer'
              accessibilityRole='button'
              accessibilityHint='Deze knop opent een raampje met de Disclaimer informatie'
                style={[{ flex: 23}, isHovered ? {borderBottomWidth: 1, borderBottomColor: 'black'} : {}]} 
                onPress={disclaimer}
                ref={ref}
              > 
                <P>Disclaimer</P>
              </TouchableOpacity>
              : <View ref={ref}></View>  
            }

            { global.settings.show_cookies === '1' ?
              <TouchableOpacity 
              accessibilityViewIsModal={true}
              accessibilityLabel='Cookies'
              accessibilityRole='button'
              accessibilityHint='Deze knop opent een raampje over de cookies op onze site'
                style={[{ flex: 19 }, isHovered1 ? {borderBottomWidth: 1, borderBottomColor: 'black'} : {}]} 
                onPress={cookies}
                ref={ref1}
              >
                <P>Cookies</P>
              </TouchableOpacity>
              : <View ref={ref1}></View>  
            }

            { global.settings.show_privacy === '1' ?
              <TouchableOpacity 
              accessibilityViewIsModal={true}
              accessibilityLabel='Privacy'
              accessibilityRole='link'
              accessibilityHint='Deze link brengt je naar de privacy statement van Milleu Centraal'
                style={[{ flex: 18 }, isHovered2 ? {borderBottomWidth: 1, borderBottomColor: 'black'} : {}]} 
                onPress={() => openUrlNewTab('https://www.milieucentraal.nl/over-milieu-centraal/privacy/')}
                ref={ref2}
              >
                <P>Privacy</P>
              </TouchableOpacity>
              : <View ref={ref2}></View>  
            }

            { global.settings.show_toegankelijkheid === '1' ?
              <TouchableOpacity 
              accessibilityViewIsModal={true}
              accessibilityLabel='Toegankelijkheid'
              accessibilityRole='button'
              accessibilityHint='Deze knop opent een raampje met informatie over onze toegankelijkheid'
                style={[{ flex: 35 }, isHovered3 ? {borderBottomWidth: 1, borderBottomColor: 'black'} : {}]} 
                onPress={toegankelijkheid}
                ref={ref3}
              >
                <P>Toegankelijkheid</P>
              </TouchableOpacity>
              : <View ref={ref3}></View>  
            }

          </View>
        </View>
      :
      <View style={{ flexDirection: 'row', flex: 100, borderTopWidth: 1, borderColor: '#999999', paddingTop: 10, paddingBottom: 5, width: '100%' }}>
        <View style={{ flex: 40, marginTop: 5, alignItems: align, paddingLeft: align ? 25 : 0 }}>
          <View style={{ flexDirection: 'row', flex: 1, paddingLeft: 15, paddingVertical: 19 }}>

            { global.settings.show_disclaimer == 1 ?
               <TouchableOpacity
                  accessibilityLabel='Disclaimer'
                  accessibilityRole='button'
                  accessibilityHint='Deze knop opent een raampje met de Disclaimer informatie'
                  style={{ marginRight: 25, marginTop: 3}} 
                  onPress={disclaimer}
                  ref={ref}
                >
                <P isHovered={isHovered}>Disclaimer</P>
              </TouchableOpacity>
              : <View ref={ref}></View>  
            }

            { global.settings.show_cookies == 1 ?
              <TouchableOpacity 
                accessibilityLabel='Cookies'
                accessibilityRole='button'
                accessibilityHint='Deze knop opent een raampje over de cookies op onze site'
                style={{ marginRight: 25, marginTop: 3}}
                onPress={cookies}
                ref={ref1}
              >
                <P isHovered={isHovered1}>Cookies</P>
              </TouchableOpacity>
              : <View ref={ref1}></View> 
            }

            { global.settings.show_privacy == 1 ?
              <TouchableOpacity 
                accessibilityLabel='Privacy'
                accessibilityRole='link'
                accessibilityHint='Deze link brengt je naar de privacy statement van Milleu Centraal'
                  style={{ marginRight: 25, marginTop: 3}} 
                  onPress={() => openUrlNewTab('https://www.milieucentraal.nl/over-milieu-centraal/privacy/')}
                  ref={ref2}
                >
                <P isHovered={isHovered2}>Privacy</P>
              </TouchableOpacity>
              : <View ref={ref2}></View>  
            }

            { global.settings.show_toegankelijkheid == 1 ?
              <TouchableOpacity
              accessibilityLabel='Toegankelijkheid'
              accessibilityRole='button'
              accessibilityHint='Deze knop opent een raampje met informatie over onze toegankelijkheid'
                style={{ marginRight: 5, marginTop: 3}}
                onPress={toegankelijkheid}
                ref={ref3}
              >
                <P isHovered={isHovered3}>Toegankelijkheid</P>
              </TouchableOpacity>
              : <View ref={ref3}></View>  
            }

            </View>
          </View>
        </View>
      }
    </View>
  );      
}