// Libraries
import React, { useContext, useState } from 'react';
import { View, Text, StyleSheet, ImageBackground, Dimensions, TouchableOpacity, Image} from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_PARAM, RESET_PARAM } from '../context/actions/commonActions';

// Components
import { P, H1, H2, H3, H4, Link } from '../components/text';
import { PictoBox } from '../components/images';
import { Button } from './button';
import { textReplace } from '../functions/common';

// Default component
export default function LeftContainer( props ) {
  const [htmlOverlay, setHtmlOverlay] = useState(false);
  const [html, setHtml] = useState('');

  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo_small.png');

  const title = props.title || 'Module titel';
  const subtitle = props.subtitle || '';
  const vraagNummer = props.vraagNummer;
  const vraagMax = props.vraagMax;
  const clickHandler = props.clickHandler;
  const image = props.image;
  const module = props.module;
  const mobile = props.mobile || false;
  const tablet = props.tablet;
  const { styles } = useStyles();

  // CookieData
  const [cookiesCaption, setCookiesCaption] = useState('');
  const [cookies, setCookies] = useState('');
  const [videoPlaceholder, setVideoPlaceholder]= useState('');
  const [videoTitle, setVideoTitle]= useState('');
  const [youtubeLink, setYoutubeLink]= useState('');

  let fontAfwijking = false;
  if (global.settings.font_regular !== 'ProximaNova-Regular.otf') {
    fontAfwijking = true;
  }

  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;

  const home = () => {
    commonDispatch({ type: RESET_PARAM });
    props.navigation.push( "Main", {module: module.mod_naam });
  }

  const opnieuwInvullen = () => {
    const param = { goto: "first", chain: [] };
    commonDispatch( { type: SET_PARAM, value: param } );
    props.navigation.push( "Main", {module: module.mod_naam });
  }

  return ( 
    <View style={{ backgroundColor: Colors[12], flex: Dimensions.get('window').width < 1000 ? 45 : 35, minHeight: Dimensions.get('window').height }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingHorizontal: 40, paddingTop: 10}}>
      {/* <View style={{ flexDirection: 'row'}}>
        {global.settings.inspiration_page !== "0" ?
          <>
            <TouchableOpacity accessibilityLabel={'Home, deze knop navigeert terug naar de home.'} accessibilityRole='button' style={{ flexDirection: 'row', height: (mobile ? 40 : 65) }} onPress={() => home()}>
              <H4 color={Colors[9]} fontSize={mobile ? 14 : 16}>{'Home'}</H4>
            </TouchableOpacity>
            <View style={{ flexDirection: 'row', justifyContent: 'center', width: 20, top: fontAfwijking ? 10 : 8 }}>
              <FontAwesome5 name="chevron-right" style={{ position: 'absolute', margin: 0, transform: 'translateY(-50%)'}} size={(mobile ? 14 : 13)} color={Colors[9]} />
            </View>
          </>
          : null
        }
        <TouchableOpacity accessibilityLabel={'Startvragen, deze knop navigeert terug naar het begin van de vragen'} accessibilityRole='button' style={{ flexDirection: 'row', height: (mobile ? 40 : 65) }} onPress={() => opnieuwInvullen()}>
          <H4 color={Colors[9]} fontSize={mobile ? 14 : 16}>{'Startvraag'}</H4>
        </TouchableOpacity>
      </View> */}
      </View>
      <View style={{ padding: tablet ? 45 : Dimensions.get('window').width < 1000 ? 20 : 40, top: -20}}  onClick={clickHandler}>
      { vraagNummer && module.mod_teller_actief == 1 ? <View style={{ marginRight: 20, marginBottom: 10}}>
        <H4 fontFamily='Regular' accessible={true} fontSize={ tablet ? 16 : 18 }>{'Vraag ' + vraagNummer + '/' + vraagMax}</H4>
        </View> : null }
        <H4 fontWeight='700' accessible={true} maxWidth={ Dimensions.get('window').width < 1000 ? '175%' : '100%' } fontSize={tablet ? 18 : 21} color={Colors[17] !== '' ? Colors[17] : Colors[2]}>{ title }</H4>
        <View style={{ marginVertical: 10 }}>
          <div style={{ fontFamily: "Regular", fontSize: tablet ? 16 : 18, lineHeight: '120%', color: Colors[9] }} dangerouslySetInnerHTML={{ __html: subtitle }} />
        </View>
        {image ? 
        <View style={{alignItems: 'flex-start'}}>
          <img src={image} style={{ maxWidth: '100%', height: mobile ? 70 : 'auto'}}/>
          {/* <Image accessible style={styles('image')} source={{ uri: image}}/> */}
        </View>
        : null }
      </View>
    </View>
  );      
}

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      image: {
        height: 350,
        marginTop: 15,
        width: 350,
        resizeMode: "contain",
        alignSelf: 'flex-start'
      },
    },
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      image: {
        height: 240,
        marginTop: 15,
        width: 240,
        resizeMode: "contain",
        alignSelf: 'flex-start'
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      image: {
        height: 400,
        marginTop: 15,
        width: '100%',
        resizeMode: "contain",
        alignSelf: 'flex-start'
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
    }
  }
)