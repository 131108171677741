// Libraries
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { StyleSheet, TouchableOpacity, TouchableWithoutFeedback, Dimensions, Switch as RNSwitch, Text, View, Platform } from 'react-native';
import { Octicons, AntDesign } from '@expo/vector-icons';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// components
import { P, Label, H1, H2, H3, H4, Space } from '../components/text';
import IconSetter from './iconSetter';

//Default function
export default function CustomCheckCard( props ) {
  Colors = FncColors();
  const { styles } = useStyles();

  const onPress = props.onPress || function (val) { console.log('maak onPress aan op radio button: ' + val) };
  const value = props.value || '';
  const data = props.data || '';
  const maxWidth = props.maxWidth;
  const longtekst = props.longtekst || false;
  const icons = props.icons;
  const mobile = props.mobile || false;
  const tablet = props.tablet || false;
  const index = props.index;
  const type = props.type || 'radio';
  let tabletLandscape = false;
  if(Dimensions.get('screen').width  >= 1024 && Dimensions.get('screen').height <= 820){
    tabletLandscape = true;
  }

  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <View style={{ marginHorizontal: 15, maxWidth: maxWidth ? maxWidth : '100%', marginTop: (mobile == false ? 30 : 0) }}>
      <TouchableWithoutFeedback 
        accessibilityRole='menuitem'
        onPress={() => onPress()}
        value={value}
        ref={ref}
      >
        <View>
          <View style={[mobile == true ? [styler.cardMobile, {borderRadius: global.settings.border_radius}] : tablet ? [styler.cardTablet, { borderRadius: global.settings.border_radius, minHeight: 80,}] : [ styler.card, { borderRadius: global.settings.border_radius, minHeight: 80,}], {borderWidth: 1, borderColor: (isHovered || value ? Colors[4] : "#E8E8E8"), transition: '0.3s'}]}>
            { mobile == false && icons[index].icoon ?
              <View style={{ marginBottom: 15 }}>
                <IconSetter family={icons[index].icoon.slice(0, icons[index].icoon.indexOf('.'))} name={icons[index].icoon.slice(icons[index].icoon.indexOf('.')+ 1)} size={60} />
              </View>
            :
              null
            }
            <View style={styles('tekstStyling')}>
              <P fontSize={ icons[index] ? 16 : 17} fontFamily='Regular' textAlign='left' maxWidth={longtekst ? '100%' : '100%'} color={value ? Colors[4] : Colors[9]}>{data.tekst}</P>
            </View>
          </View>
          <View style={ tabletLandscape ? { position: 'absolute', top: 17, right: 5, backgroundColor: 'transparent'} : styles('checkPosition')}>
            {
              value ? 
              <AntDesign name="checkcircle" size={20} color={Colors[18]} />
              : null
            }
          </View>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
} 

const styler = StyleSheet.create({
  checkCircle: { 
    position: 'absolute', 
    right: 15
  },
  checkCircleIcon: {
    position: 'absolute', 
    right: 15, 
    top: -110 
  },
  checkCircleMobile: { 
    position: 'absolute', 
    right: 0, 
  },
  card: {
    borderWidth: 1,
    width: "100%",
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 20,
    cursor: 'pointer',
    backgroundColor: '#f7f7f7'
  },
  cardMobile: {
    borderWidth: 1,
    minWidth: Dimensions.get('screen').width * 0.8,
    marginHorizontal: 10,
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginTop: 15,
    alignSelf: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderColor: '#EDEDED',
    backgroundColor: '#f7f7f7',
  },
  cardTablet: {
    borderWidth: 1,
    minWidth: '100%',
    marginHorizontal: 10,
    paddingVertical: 10,
    marginTop: 15,
    alignSelf: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderColor: '#EDEDED',
    backgroundColor: '#f7f7f7'
  },
  tekstMobile: {
    alignItems: 'flex-start',
    marginVertical: 10,
  },
  tekstTablet: {
    alignItems: 'flex-start',
    paddingVertical: 10,
  }
});

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      tekstStyling: {
        alignItems: 'flex-start',
        paddingHorizontal: 10,
      },
      checkPosition: {
        position: 'absolute', 
        top: 19, 
        right: -2, 
        backgroundColor: 'transparent'
      }
    },
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      tekstStyling: {
        paddingHorizontal: 10,
      },
      checkPosition : { 
        position: 'absolute', 
        top: 17, 
        right: 5, 
        backgroundColor: 'transparent'
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      tekstStyling: {
        alignItems: 'flex-start',
      },
      checkPosition : { 
        position: 'absolute', 
        top: 5, 
        right: 5, 
        backgroundColor: 'transparent'
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
      tekstStyling: {
        alignItems: 'flex-start',
      },
    }
  }
)