// Libraries
import React, {useRef} from 'react';
import { View, StyleSheet, Dimensions, TouchableWithoutFeedback} from 'react-native';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';
import { Feather, FontAwesome5, Ionicons, FontAwesome} from '@expo/vector-icons'; 

// Styles
import FncColors from '../styles/colors';
let Colors = [];
import { CreateResponsiveStyle, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

// Components
import { H3, P } from '../components/text';
import { PictoBox } from '../components/images';
import { Button } from './button';

// Context

// Default component
export default function Header( props ) {
  Colors = FncColors();
  const Logo = require('../assets/images/' + global.settings.image_folder + '/logo.png');

  const logo = props.logo || { source: Logo }
  var title = props.title || '';
  const subtitle = props.subtitle || '';
  const mobile = props.mobile || false;
  const tablet = props.tablet;
  const onClick = props.onClick || function () { console.log('Terug naar home') };

  // titel bewerken: afkorten en eventueel over 2 regels verdelen
  // alleen bij mobiel
  const maxLenght = 23;
  if ( mobile && title.length > maxLenght ) {
    // zoeken naar een spatie zo dicht mogelijk bij de max lengte
    var tmp = title.slice(0, maxLenght);
    var p = tmp.lastIndexOf(" ");
    if (p > 8){
      // spatie gevonden, hierop afbreken en nieuwe regel toevoegen
      var start = tmp.slice(0, p) + "\n"; 
      var rest = title.slice(p).trim();
      // als restant nog te lang is, weer afbreken
      if (rest.length > maxLenght) rest = rest.slice(0, maxLenght).trim() + '...';
      title = start + rest;
    }
    else{
      // geen bruikbare spatie gevonden, afbreken op maximale lengte
      title = title.slice(0, maxLenght).trim() + '...';
    }
  }


  // const refPrev = useRef(null);
  // const isHoveredPrev = useHover(refPrev);
  // const refDelen = useRef();
  // const isHoveredDelen = useHover(refDelen);

  return ( 
    <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderColor: '#EEF2EF', paddingBottom: 10 }}>
      {logo ?
      <View style={ mobile ? { paddingRight: 75, bottom: 15 } : { marginLeft: 20, marginTop: 10, marginRight: 15 }}>
        <PictoBox mobile={mobile} width={125} height={65} source={ logo.source } onPress={ onClick } />
      </View>
      : null }

      <View
      style={ mobile ? { marginTop: 15, height: 30, borderLeftWidth: 2, borderLeftColor: Colors[9]} : { justifyContent: 'center', marginTop: 15, margingBottom: 10, borderLeftWidth: 2, borderLeftColor: Colors[9], }}>
        <View 
          style={ mobile ? {  flexDirection: 'row', flex: 1, paddingLeft: 15, bottom: 0 } : { paddingLeft: 20, paddingTop: 0 }}
        >
          <View style={{ alignSelf: 'flex-start', marginBottom: 2, top: global.settings.titel_margin_bottom ? - global.settings.titel_margin_bottom : null}}>
            <H3 style={{fontWeight: '700'}} accessible={true} color={Colors[9]}>{title}</H3>
          </View>
           { mobile ? null :<P fontWeight={'400'}>{subtitle}</P>}
        </View>
      </View>
    </View>
  );      
}

// Style
const styler = StyleSheet.create({
  scrollView: { 
    backgroundColor: Colors[0],
    minHeight: "calc(100vh - 212px)",
    marginTop: 40,
    marginLeft: 10, 
    marginBottom: 20,
  },
  rowView: { 
    flexDirection: 'row', 
    paddingHorizontal: 40, 
    flexWrap: 'wrap',
  },
  widthView: {
    paddingHorizontal: 40, 
    flexWrap: 'wrap',
    width: "50%"
  },
  objectView: {
    flexDirection: "row",
    width: "100%",
  },
  buttonArea: {
    flexDirection: 'row',
    justifyContent: 'end',
    width: '100%',
    marginBottom: 15,
    bottom: 15,
    paddingRight: 40,
  },
  buttonPrev: {
    borderWidth: 1,
    marginRight: 20,
    alignSelf: 'flex-start',
    borderRadius: 30,
    zIndex: 1,
  },
  buttonNext: {
    borderWidth: 1,
    alignSelf: 'flex-start',
    borderRadius: 30,
    zIndex: 1,
    backgroundColor: '#f5f2f2',
    marginRight: 20,
  },
  hover: {
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    cursor: 'not-allowed'
  },
  allowHover: {
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    cursor: 'pointer'
  },
  rowViewMobile: {
    flexDirection: 'row', 
    paddingHorizontal: 40,
    flexWrap: 'wrap'
  },
  objectViewMobile: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    marginBottom: 15,
  },
  buttonAreaMobile: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 30,
    marginHorizontal: 20,
  },
  buttonPrevMobile: {
    width: "45%",
    alignSelf: 'flex-start',
    borderRadius: 30,
    marginRight: 5,
    zIndex: 1,
  },
  buttonNextMobile: {
    width: "45%",
    borderRadius: 30,
    marginLeft: 5,
    alignSelf: 'flex-end',
    zIndex: 1,
    backgroundColor: '#f5f2f2',
  },
  scrollViewMobile: { 
    backgroundColor: Colors[0], 
    marginTop: 20, 
    minHeight: Dimensions.get('screen').height* 0.5 
  },
  inputViewMobile: {
    width: "100%"
  }
});

const useStyles = CreateResponsiveStyle(
  {},
  {
    // Will apply the size 30 font to large and extra large devices
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      titel: {
        fontFamily: 'Regular',
        fontWeight: '700',
        fontSize: 28,
      },
      card: {
        width: '100%'
      },
      container: {
        flex: 1,
        padding: 40,
        backgroundColor: 'white',
      },
      col: {
        width: '100%',
        justifyContent: 'center',
      },
      splitScreen: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
      },
      image: {
        height: 300,
        width: '80%',
        resizeMode: "contain",
        justifyContent: "flex-end"
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)] : {
      col: {
        width: '48.5%',
        margin: 50,
      },
      card: {
        width: '49.5%'
      },
      splitScreen: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        flex: 1,
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)] : {
      col: {
        width: '48.5%',
        margin: 50
      },
      card: {
        width: '33%'
      },
    }
  }
)