// Libraries
import React from 'react';
import { StyleSheet, TouchableOpacity, Switch as RNSwitch, Text, View, Platform} from 'react-native';
import { RadioButton as RNradioButton } from 'react-native-paper';

// Components
import { P, H1, H2, H3, Space, SplitAlineas } from '../components/text';

// Styles
import FncColors from '../styles/colors';
let Colors = [];


export default function RadioButton( props ){
  Colors = FncColors();
  const onPress = props.onPress || function () { console.log('maak onPress aan op IconButton') };
  const value = props.value || 1;
  const checked = props.checked || 'unchecked';
  const label = props.label || 'plaats een label';

  return (
    <View style={{ flexDirection: 'row' }}>
      <RNradioButton
        color='#046a6c'
        value={ value }
        status={ checked }
        onPress={ onPress }
      />
      <View style={{ marginTop: 6}}><TouchableOpacity onPress={ onPress }><P>{ label }</P></TouchableOpacity></View>
    </View>
  );
}