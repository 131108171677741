// Vaste kleuren
const kleuren = {
  oranje: "#f76e42",
  lichtOranje: "#fbb6a0",
  wit: "white",
  grijs: "#bfbfbf",
  achtergrondKleur: "#046a6c",
  prijsKleur: "#a6a6a6",
  prijsKleurFX: "#878787",
  lichtGrijs: "#cccccc",
  donkerGrijs: "#a3a3a3",
  donkerGrijsPlus: "#828282",
  zwartGrijs: "#8a8a8a",
  errorKleur: "#e63946",
  inputSelectie: "#ececec",
  tekstKleur: "#8a8a8a",
  zalm: "#ff9680",
  babyBlauw: "#5a92f6",
};

export default kleuren;
