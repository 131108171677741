// Libraries
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { permissions } from '../functions/permissions';
import * as Network from 'expo-network';

// components
import Loader from '../components/loader';
import { setData, getData } from '../functions/storage';
import { SET_MODULE, SET_PARAM, SET_PARAM_CACHE, SET_DEBUG } from '../context/actions/commonActions';


import { GlobalDispatcher, GlobalContext } from '../context/globalState';

// Default component
let hasNetwork = false;

const ReadStorage = ( props ) => {
  const commonDispatch = useContext( GlobalDispatcher ).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  let debug = commonContext.debug;
  var hasPermissions = false;
  const [ loading, setLoading ] = useState( true );
  const [ readStorageDone, setReadStorageDone ] = useState( false );
  
  //if (debug) console.log( 'commonContext', commonContext );

  if(!readStorageDone){
    setReadStorageDone(true);

    getData('debug', commonContext.debug)
      .then(result => {
        debug = result;
        commonDispatch( { type: SET_DEBUG, value: result } );
      }
    );


    getData('module', commonContext.module)
      .then(result => {
        if (debug) console.log(result);
        commonDispatch( { type: SET_MODULE, value: result } );
      }
    );

    getData('param', commonContext.param)
      .then(result => {
        if (debug) console.log(result);
        commonDispatch( { type: SET_PARAM, value: result } );
      }
    );

    getData('param_cache', commonContext.param_cache)
      .then(result => {
        if (debug) console.log(result);
        commonDispatch( { type: SET_PARAM_CACHE, value: result } );
      }
    );
    setLoading(false);
  }

  if( loading ){
    return <Loader/>;
  }
  else {
      return (
        <>
          { props.children }
        </> 
      )
  }
}

export default ReadStorage;

