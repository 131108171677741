// Libraries
import React, { useState, useContext, useEffect} from 'react';
import { View, TouchableOpacity, Image } from "react-native";

// Styles
import FncColors from '../styles/colors';
let Colors = [];

// Components
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { P, H1, H2, H3 } from '../components/text';
import HarmonicaCard from '../components/harmonicaCard';

// Functions

export default function Harmonica (props) {
    Colors = FncColors();
    const items = props.items || [];

    return (
        <View style={{ flexDirection: 'column', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
        {
            items.map( ( item, index ) => {
                return (
                    <HarmonicaCard
                        key = { index }
                        item = { item }
                    />                    
                )
            })
        }
        </View>
        );
};
